$(document).ready(function () {
    $('#carer_search_address').on(
        'click',
        function () {
            let post_code_1 = $('#carer_post_code_1').val()
            let post_code_2 = $('#carer_post_code_2').val()
            if (!post_code_1 || !post_code_2) {
                console.log("post_code is empty")
                return
            }

            $.ajax({
                url: '/carers/get_address',
                type: "get",
                data: { post_code: post_code_1 + post_code_2 }
            })
        }
    );

    $('#staff_search_address').on(
        'click',
        function () {
            let post_code_1 = $('#staff_post_code_1').val()
            let post_code_2 = $('#staff_post_code_2').val()
            if (!post_code_1 || !post_code_2) {
                console.log("post_code is empty")
                return
            }

            $.ajax({
                url: '/staffs/get_address',
                type: "get",
                data: { post_code: post_code_1 + post_code_2 }
            })
        }
    );

    $('#supporter_search_address').on(
        'click',
        function () {
            console.log("click")
            let post_code_1 = $('#supporter_post_code_1').val()
            let post_code_2 = $('#supporter_post_code_2').val()
            if (!post_code_1 || !post_code_2) {
                console.log("post_code is empty")
                return
            }

            $.ajax({
                url: '/supporters/get_address',
                type: "get",
                data: { post_code: post_code_1 + post_code_2 }
            })
        }
    );
})